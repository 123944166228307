.container {
  width: 100%;
}

.terminal {
  box-shadow: 1px 2px 10px rgba(83, 83, 83, 0.24);
  max-width: 800px;
  margin: 0 auto;
  border: 3px solid #333;
}

.terminal.light {
  border-color: #333;
}

.titlebar {
  background: #333;
  color: #ededed;
}

.terminal.light .titlebar {
  background: #ccc;
  color: #282828;
}

.tabs {
  display: flex;
  overflow: auto;
  font-size: 0.8em;
}

.tab {
  white-space: pre;
  position: relative;
}

.radio {
  position: absolute;
  opacity: 0;
  margin: 0;
}

.tab input:focus + label span {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
  outline: 0;
}

.tab label {
  cursor: pointer;
  padding: 3px;
  display: block;
}

.tab label span {
  display: block;
  padding: 7px;
}

.tab:hover {
  background: #494a49;
}

.tab.active {
  background: #000100;
}

.terminal.light .titlebar .tab.active {
  background: #fffffd;
}
.terminal.light .titlebar .tab:hover {
  background: #e5e5e7;
}

.code {
  display: block;
  font-size: 18px;
  white-space: pre;
  overflow: auto;
  padding: 10px 10px 40px;
  min-height: 40vh;
  max-height: 50vh;
}

.footer {
  font-size: 0.8em;
  color: var(--toolbar__color);
  text-align: right;
  padding: 1px 3px;
}

.footer a {
  color: var(--link-colour--visited);
}
