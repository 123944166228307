.container {
  padding: 40px 20px;
  color: var(--btn__colour);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 450px));
  grid-template-rows: min-content;
  background: var(--toolbar__background);
  justify-content: center;
  align-content: center;
  min-height: 100vh;
}

@media (min-width: 1024px) {
  .container {
    padding: 60px 40px;
    gap: 40px;
  }
}

.hr {
  border-color: var(--btn__bg-color);
}

.section p:last-child {
  margin: 0;
}

.section {
  padding: 0 0 60px;
  border-radius: 0.25em;
}

.section a {
  color: var(--link-colour);
}

.section a:visited {
  color: var(--link-colour--visited);
}

.section p,
.section li {
  line-height: 1.4;
}

.heading {
  margin: 0 0 10px;
  font-size: 2.2rem;
}

.container button {
  background-image: var(--btn__bg-img);
  border-radius: 0.25em;
  border: var(--btn__bg-border);
  color: var(--btn__colour);
  padding: 3px 10px;
  cursor: pointer;
}
.container button:focus {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
  outline: 0;
}
.container button:hover {
  background-image: var(--btn__bg-img--hover);
  background-position: -0.5em;
}

.key {
  font-family: 'Cascadia Code', 'Fira Mono', 'IBM Plex Mono', 'Menlo', Consolas,
    monospace, monospace;
  border: 1px solid;
  border-radius: 4px;
  font-size: 1.5rem;
  padding: 2px 6px;
}
