.container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
}

@media (min-width: 1024px) {
  .container {
    width: initial;
  }
}

.label {
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
}

.select {
  font-size: 2rem;
  font-family: var(--font-stack);
  max-width: 100%;
  width: 100%;
  padding: 6px 10px;
  border: var(--btn__bg-border);
  border-left: 0;
  border-right: 0;
  background-image: var(--btn__bg-img);
  color: var(--btn__colour);
  height: 100%;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
}

select:focus {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
}

.select option {
  width: 300px;
  background-color: var(--btn__bg-color);
}

.arrow {
  flex: 0 0 auto;
}

.direction {
  display: flex;
  align-items: center;
  background-image: var(--btn__bg-img);
  border-radius: 0.25em;
  border: var(--btn__bg-border);
  color: var(--btn__colour);
  padding: 6px 10px;
  cursor: pointer;
  height: 100%;
  /* stop ipad zooming in when pressing button repeatedly */
  touch-action: manipulation;
  font-size: 2rem;
  /* Safari adds left and right margin  */
  margin: 0;
}
.direction:first-child {
  border-radius: 0.25em 0 0 0.25em;
}
.direction:last-child {
  border-radius: 0 0.25em 0.25em 0;
}
.direction:focus {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
  outline: 0;
}
.direction:hover {
  background-image: var(--btn__bg-img--hover);
  background-position: -0.5em;
}
