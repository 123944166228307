.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.sidebar {
  transition: background 0.05s ease-in-out;
}
@media (min-width: 1024px) {
  .sidebar {
    flex: 0 1 35%;
    height: 100%;
    overflow: auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    border: 3px solid;
  }
}

.content {
  flex: 1 0 auto;
  position: relative;
  display: flex;
}

.title {
  text-align: center;
  font-size: 2.8rem;
  text-decoration: none;
  border-bottom: 3px solid;
  color: inherit;
  line-height: 1;
}

h1 {
  margin: 0;
}

.light {
  color: #282c34;
}

.dark {
  background-color: #282c34;
  color: white;
}

.actions {
  border-top: 3px solid;
  border-bottom: 3px solid;
  margin: auto 0 0;
  padding: 3px;
  display: flex;
}
@media (min-width: 1024px) {
  .actions {
    border-bottom: 0;
  }
}

.buttons {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
}

.help {
  background: transparent;
  border: 0 solid;
  padding: 0;
  cursor: pointer;
  margin: 0 3px 0 0;
}

.help:focus {
  outline: 1px dotted;
}

.help svg {
  display: block;
}

.git {
  display: inline-block;
  border: 0 solid;
  margin: 0 3px 0 0;
}

.git svg {
  padding: 3px;
  display: block;
}

.download {
  background: transparent;
  border: 0 solid;
  padding: 0;
  cursor: pointer;
}

.download:focus {
  outline: 1px dotted;
}

.download svg {
  display: block;
  transform: translateY(1px);
}
