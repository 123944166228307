.matrix {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  width: 100%;
}

@media (min-width: 360px) {
  .matrix {
    grid-column-gap: 5px;
  }
}

@media (min-width: 1024px) {
  .matrix {
    width: auto;
    grid-column-gap: 10px;
  }
}

.cell {
  font-size: 16px;
  text-align: center;
}

@media (min-width: 1024px) {
  .cell {
    font-size: 2vw;
    padding: 0 10px;
  }
}

@media (min-width: 1280px) {
  .cell {
    font-size: 32px;
  }
}

.credits {
  font-size: 0.8em;
  color: var(--toolbar__color);
  text-align: right;
  padding: 1px 3px 2px;
}

.credits a {
  color: var(--link-colour--visited);
}
