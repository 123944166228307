.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 40px;
  transition: background 0.05s ease-in-out;
  overflow: auto;
  padding: 10px;
  max-width: 1024px;
  margin: auto;
  flex: 1 1 auto;
}
@media (min-width: 1024px) {
  .container {
    padding: 10px;
    justify-content: safe center;
    align-items: center;
  }
}

.loading {
  height: 100%;
}

.buttons {
  padding: 20px 0;
}
@media (min-width: 1024px) {
  .buttons {
    display: flex;
    justify-content: center;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: var(--btn__bg-img);
  border-radius: 0.25em;
  border: 1px solid rgba(200, 200, 200, 0.15);
  color: var(--btn__colour);
  padding: 6px 16px;
  font-size: 2rem;
  border-width: 2px;
  margin: 0 auto 10px;
  cursor: pointer;
  width: 100%;
  max-width: 500px;
}
@media (min-width: 1024px) {
  .button {
    margin: 0 5px;
    width: initial;
  }
}

.button:focus {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
  outline: 0;
}

.button:hover {
  background-image: var(--btn__bg-img--hover);
  background-position: -0.5em;
}

.button svg {
  margin: 0 5px 0 0;
}

.button.primary {
  background-color: #0366d6;
  background-image: none;
  color: #ededed;
  font-weight: 500;
}

.toggles {
  display: flex;
  overflow: auto;
  margin: 10px 0 0;
}

.select {
  margin: 20px auto 0;
  max-width: 800px;
}
