.container {
  position: relative;
  display: flex;
}

.container:first-child {
  margin: 0 10px 20px auto;
}
.container:last-child {
  margin: 0 auto 20px 0;
}
@media (min-width: 1024px) {
  .container:first-child {
    margin: 0 10px 10px 0;
  }
  .container:last-child {
    margin: 0 0 10px 0;
  }
}
.label {
  display: inline-block;
  padding: 3px;
  cursor: pointer;
  color: var(--btn__colour);
  background-image: var(--btn__bg-img);
  border-radius: 0.25em;
  border: var(--btn__bg-border);
  display: inline-flex;
  align-items: center;
}
@media (min-width: 1024px) {
  .label {
    padding: 0 3px;
  }
}
.label:first-child {
  border-radius: 0.25em 0 0 0.25em;
  border-right: 0;
}

.label:last-child {
  border-radius: 0 0.25em 0.25em 0;
}

.label:hover {
  background-image: var(--btn__bg-img--hover);
  background-position: -0.5em;
}

.label.active {
  background-color: var(--btn__bg-color);
  background-image: none;
  border-color: var(--btn__border-colour__active);
  box-shadow: var(--btn__insert);
}

.label svg {
  /* this just stacks it above the input  */
  position: relative;
  margin: 0 5px 0 0;
}

.radio {
  position: absolute;
  opacity: 0;
}

.outline {
  padding: 3px 10px;
  display: flex;
  align-items: center;
}

.radio:focus + .outline {
  box-shadow: 0 0 0 0.2em rgba(79, 140, 201, 0.5);
}
