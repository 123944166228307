.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #090300;
  color: #fff;
}

.container h1 {
  display: flex;
  align-items: center;
}

.logo {
  margin: 0 20px 0 0;
}
